<script>
import { setComputed } from '@state/helpers'
import Layout from '@layouts/main'

import AboutTimeLine from '@components/AboutTimeLine'
import AboutVideo from '@components/AboutVideo'

import TheFooter from '../layouts/TheFooter'

export default {
  components: { Layout, AboutTimeLine, AboutVideo, TheFooter },
  props: {
    timeLineModule: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      window_w: 0,
      banner: {
        'zh-TW': {
          title: 'AboutBanner.vue',
          image: require('@assets/images/about/about_banner.jpg'),
          content: '究極美味，<br>旨味三昧。',
          class_name: '',
          hasMask: 0,
        },
        en: {
          title: 'AboutBanner.vue',
          image: require('@assets/images/about/about_banner.jpg'),
          content: '究極美味，<br>旨味三昧。',
          class_name: '',
          hasMask: 0,
        },
      },
      mainContent: {
        'zh-TW': {
          content: 
            '橘色集團的創立從橘色涮涮屋為起始點...<br />橘色涮涮屋由過往長期旅居美國並在美國即從事專業餐飲的袁永定先生所創辦。 <br /><br />當初以希望能夠開設心目中最嚮往的頂級涮涮鍋餐廳，設定目標後，嗅聞了當時的市場動向，加上過往的豐富餐飲經驗、在客戶服務獨特的服務心法，在2001年，以橘色涮涮屋打開了橘色事業的開端。<br /><br />隨著時光推移，以顧客服務導向、優雅舒適空間等核心經營理念陸續籌劃了Extension 1 by 橘色、Sakura健康生活館、美式早午餐餐廳M One Cafe、M One Spa，將生活中各式可以透過體驗的享受，以橘色的服務衷心，以嚮同好。'
        },
        'en': {
          content: 
            'For the Orange Corporation, it all started with Orange Shabu...<br />Orange Shabu was founded by Scott Yuan Yong-Ding, a professional in the dining industry, who lived in the US for a long time. <br /><br />After stating his goal of opening an ideal top-end shabu shabu restaurant, Yuan studied the direction of the market and, with his abundant dining experience and unique customer service methods, in 2001, Orange Shabu had been born and thus became the starting point of the Orange Corporation.<br /><br />As time passed, the Orange Corporation continued to follow its core business concepts of  providing customer-oriented service and an elegant and comfortable space. Since inception, Extension 1 by Orange, Sakura Spa, M One Cafe and M One Spa have been launched. Always keeping in mind its initial intention of providing service, it offers customers opportunities to fully enjoy various types of life experience.'
        }
      },
      circle: {
        'zh-TW': {
          title: 'AboutCircle.vue',
          items: [
            {
              title: 'attentive',
              image: require('@assets/images/about/attentive.svg'),
              content:
                '橘色集團用「心」貫穿多年來的堅持與理念。我們貼近每位消費者的心，讓顧客感到絕對安心。同時，橘色更是堅持品質，讓消費者有絕對的信心，並且凝聚每位夥伴的向心力，由內而外散發同理心去對待每一位橘色的客人，是我們一直堅持的品牌精神。讓橘色的用心，體現在各個角落。',
            },
            {
              title: 'innovation',
              image: require('@assets/images/about/innovation.svg'),
              content:
                '「橘色」代表勇於創新、不斷突破。堅持一代經營的心法思維，同時也勇於嘗試，不停進化，永遠想在最前端，只為再多一份貼心感動。',
            },
            {
              title: 'continuously',
              image: require('@assets/images/about/perseverance.svg'),
              content:
                '橘色保有一路堅持的核心文化，將顧客服務的無形心法永續傳承。傳遞正面的思想、正向的能量，由內而外形成一個良好的循環，建立起優質生態圈，永續發展橘色品牌，將橘色的用心體現在世界各個角落！',
            },
          ],
        },
        en: {
          title: 'AboutCircle.vue',
          items: [
            {
              title: 'attentive',
              image: require('@assets/images/about/attentive.svg'),
              content:
                'Orange Corporation has always prioritized customers’ needs. We like to stay close to every customer’s heart, making them feel at ease. Our company has top-of-the-line quality control which has helped us gain customer confidence. We have built a team with our partners and have always treated our guests with the utmost consideration. This creates the brand spirit we insist on. We pay attention to every detail and are guided by this spirit.',
            },
            {
              title: 'innovation',
              image: require('@assets/images/about/innovation.svg'),
              content:
                'Orange is always brave about innovating and thus keeps making breakthroughs. It has kept using its long-term methods and business model. But, it also is not afraid of trying new things and evolving. It always strives to be top of the line in order to win over the hearts of even more customers.',
            },
            {
              title: 'continuously',
              image: require('@assets/images/about/perseverance.svg'),
              content:
                'For years, the Orange Corporation has maintained its core culture and passed along its unique methods for customer service. It always delivers positive thoughts and energy, building a nurturing cycle from inside out, thus establishing a premium ecosphere. Thus, the Orange brand has flourished substantially as it spreads its attention to detail to every corner of the world.',
            },
          ],
        },
      },
      circleValue: {
        deg: 0,
        index: 0,
      },
      orangeVideo: {
        'zh-TW': {
          title: '用心<br>創造美好體驗',
          content:
            '橘色集團自成立以來，用「心」貫穿品牌的堅持與理念，以「貼心服務」、「頂級食材」與「舒適空間」提供顧客極致的用餐體驗。<br>從入座開始就有專人為您服務，每樣菜色皆經專業團隊嚴格把關品選。從第一涮到最後的招牌黃金粥，不斷在舌尖交織出一篇篇美味樂章。',
        },
        en: {
          title: 'Create a wonderful experience<br>with attentivity',
          content:
            'Since its founding, Orange Corporation has taken attentivity as the core of its brand concept, providing customers with “attentive service,” “top-notch ingredients,” and a “comfortable space” to enjoy the ultimate dining experience.<br>Once seated, our guests enjoy the attentive service. Each meal is carefully created by our professional team; from the first bite to the final taste of our signature golden congee, a true symphony of flavor resounds on the taste buds!',
        },
      },
      expect: {
        'zh-TW': {
          title: '我們期待<br />創造一個令夥伴都能感到驕傲的品牌',
          content: '以「心」貫穿全品牌堅持及理念，跨足鍋物、咖啡餐飲、舒體等橘色的全事業體，嚴格挑選的頂級食材、恰到好處的服務品質、上乘空間氛圍，讓每一位貴賓就像是到朋友家作客一樣自在。<br /><br />暖心的橘色，剛好的溫度'
        },
        en: {
          title: 'We expect to<br />create a brand our partners<br /> will feel proud of',
          content: 'Taking hearty service as a core concept, the Orange Corporation has extended its business to include hotpot restaurants, coffee shops and aromatherapy spas. With carefully-selected ingredients, ideal service quality and a superb atmosphere, Orange makes every guest feel right at home.<br /><br />The heart-warming orange represents the perfect temperature'
        }
      },
      persistence: {
        'zh-TW': {
          title: '光陰流轉的漫漫歲月，<br class="d-block d-lg-none"/>橘色以不變的初衷，<br class="d-block d-lg-none"/>一路走來，始終堅持。'
        },
        en: {
          title: 'Since it began, Orange Corporation has kept its initial intentions in mind and keeps moving on.'
        }
      },
      options: {
        licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
        navigation: true,
      },
    }
  },
  computed: {
    ...setComputed,
    bannerModule() {
      return this.currentLanguage === 'zh-TW'
        ? this.banner['zh-TW']
        : this.banner['en']
    },
    mainContentModule() {
      return this.currentLanguage === 'zh-TW'
        ? this.mainContent['zh-TW']
        : this.mainContent['en']
    },
    circleModule() {
      return this.currentLanguage === 'zh-TW'
        ? this.circle['zh-TW']
        : this.circle['en']
    },
    orangeVideoModule() {
      return this.currentLanguage === 'zh-TW'
        ? this.orangeVideo['zh-TW']
        : this.orangeVideo['en']
    },
    expectModule() {
      return this.currentLanguage === 'zh-TW'
        ? this.expect['zh-TW']
        : this.expect['en']
    },
    persistenceModule() {
      return this.currentLanguage === 'zh-TW'
        ? this.persistence['zh-TW']
        : this.persistence['en']
    },
    circleStyle() {
      return {
        transform: `rotate(${this.circleValue.deg}deg)`,
        '-webkit-transform': `rotate(${this.circleValue.deg}deg)`,
      }
    },
    circleInnerStyle() {
      return {
        transform: `rotate(${0 - this.circleValue.deg}deg)`,
        '-webkit-transform': `rotate(${0 - this.circleValue.deg}deg)`,
      }
    },
    bossTranformStyle() {
      const originWidth = 1920
      const windthWidth = this.window_w

      if (this.window_w > 991) {
        let scale =
          this.window_w < originWidth ? 1 / (originWidth / windthWidth) : 1
        if (scale < 0.7) {
          scale = 0.7
        }

        return {
          transform: `scale(${scale}) translateY(-${(1 - scale) *
            50}%) translateX(-${(1 - scale) * 150}px)`,
          '-webkit-transform': `scale(${scale}) translateY(-${(1 - scale) *
            50}%)`,
          paddingLeft: `${80 - (1 - scale) * 100}px`,
          marginBottom: scale > 0.9 ? 0 : `-${scale * 70}px`,
        }
      } else {
        return {}
      }
    },
    isMobile() {
      return this.window_w < 991
    },
  },
  mounted() {
    this.getWindowWidth()
    window.addEventListener('resize', this.getWindowWidth)
  },
  destroyed() {
    window.removeEventListener('resize', this.getWindowWidth)
  },
  methods: {
    setCircleValue(index) {
      const circleFirst = 0
      const circleLast = 2

      const next =
        this.circleValue.index === circleLast
          ? circleFirst
          : this.circleValue.index + 1
      const prev =
        this.circleValue.index === circleFirst
          ? circleLast
          : this.circleValue.index - 1

      if (index === next) this.circleValue.deg = this.circleValue.deg + 120
      if (index === prev) this.circleValue.deg = this.circleValue.deg - 120

      this.circleValue.index = index
    },
    getWindowWidth() {
      this.window_w = document.body.clientWidth
    },
  },
}
</script>

<template>
  <Layout>
    <full-page :options="options" ref="fullpage">
      <section class="section" :class="$style.sectionBanner">
        <div :class="$style.banner" class="row">
          <div
            :class="$style.image"
            :style="{ backgroundImage: `url(${bannerModule.image})` }"
          ></div>
          <div :class="$style.content">
            <div v-fade-up :class="$style.text" v-html="mainContentModule.content"></div>
            <div v-fade-up :class="$style.shadow"></div>
          </div>
        </div>
      </section>
      <section class="section" :class="$style.sectionBoss">
        <div :class="$style.sectionContainer">
          <div class="row">
            <div v-fade-up :class="$style.imgBox" class="text-right">
              <img
                :alt="$t('global.YUAN')"
                :class="$style.img"
                src="~@assets/images/about/boss.png"
              />
            </div>
            <div :class="$style.textBox" :style="bossTranformStyle">
              <div>
                <img
                  v-fade-up
                  :class="$style.title"
                  :alt="$t('global.W_H_F_S')"
                  src="@assets/images/about/boss_text.png"
                />
                <div v-fade-up>
                  <h5 :class="$style.meta">{{$t("global.FOUNDER")}}</h5>
                  <h4 :class="$style.name">{{$t('global.YUAN')}}</h4>
                  <img
                    :alt="$t('global.SIGNATURE')"
                    :class="$style.sign"
                    src="@assets/images/about/boss_sign.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section" :class="$style.sectionCircle">
        <div v-fade-up :class="$style.circleBox">
          <div :class="$style.animationBox" :style="circleStyle">
            <div
              :class="[
                $style.circle,
                $style.circleA,
                circleValue.index === 0 ? $style.active : '',
              ]"
              @click="setCircleValue(0)"
            >
              <div :class="$style.circleInner" :style="circleInnerStyle">
                <div>
                  <img
                    :alt="circleModule.items[0].title"
                    :class="$style.img"
                    :src="circleModule.items[0].image"
                  />
                  <h6 :class="$style.title">{{
                    circleModule.items[0].title
                  }}</h6>
                </div>
              </div>
            </div>
            <transition name="fade" mode="out-in"
              ><p
                v-if="isMobile && circleValue.index === 0"
                :class="$style.content"
                >{{ circleModule.items[0].content }}</p
              ></transition
            >

            <div
              :class="[
                $style.circle,
                $style.circleB,
                circleValue.index === 1 ? $style.active : '',
              ]"
              @click="setCircleValue(1)"
            >
              <div :class="$style.circleInner" :style="circleInnerStyle">
                <div>
                  <img
                    :alt="circleModule.items[1].title"
                    :class="$style.img"
                    :src="circleModule.items[1].image"
                  />
                  <h6 :class="$style.title">{{
                    circleModule.items[1].title
                  }}</h6>
                </div>
              </div>
            </div>
            <transition name="fade" mode="out-in"
              ><p
                v-if="isMobile && circleValue.index === 1"
                :class="$style.content"
                >{{ circleModule.items[1].content }}</p
              ></transition
            >

            <div
              :class="[
                $style.circle,
                $style.circleC,
                circleValue.index === 2 ? $style.active : '',
              ]"
              @click="setCircleValue(2)"
            >
              <div :class="$style.circleInner" :style="circleInnerStyle">
                <div>
                  <img
                    :alt="circleModule.items[2].title"
                    :class="$style.img"
                    :src="circleModule.items[2].image"
                  />
                  <h6 :class="$style.title">{{
                    circleModule.items[2].title
                  }}</h6>
                </div>
              </div>
            </div>
            <transition name="fade" mode="out-in"
              ><p
                v-if="isMobile && circleValue.index === 2"
                :class="$style.content"
                >{{ circleModule.items[2].content }}</p
              ></transition
            >
          </div>
          <div :class="$style.textBox">
            <div :class="$style.text">
              <transition name="fade" mode="out-in">
                <template v-for="(circleItem, index) in circleModule.items">
                  <div
                    v-if="index === circleValue.index"
                    :key="circleItem.title"
                  >
                    {{ circleItem.content }}
                  </div>
                </template>
              </transition>
            </div>
          </div>
        </div>
      </section>
      <section class="section" :class="$style.sectionBannerA">
        <div class="container">
          <h2 v-fade-up :class="$style.title" v-html="expectModule.title"></h2>
          <p v-fade-up :class="$style.content" v-html="expectModule.content"></p>
          <div v-fade-up :class="$style.line"></div>
        </div>
      </section>
      <section class="section" :class="$style.sectionBannerB">
        <h2 v-fade-up :class="$style.title" v-html="persistenceModule.title"></h2>
      </section>
      <section class="section" :class="$style.sectionTimeline">
        <div
          :class="$style.img"
          :style="{
            backgroundImage: `url(${require('@assets/images/about/about_timeline_banner.png')})`,
          }"
        ></div>
        <div class="container">
          <div v-fade-up :class="$style.timeLine">
            <AboutTimeLine
              v-if="timeLineModule"
              :module-data="timeLineModule"
            />
          </div>
        </div>
      </section>
      <section class="section" :class="$style.sectionVideo">
        <div :class="$style.video">
          <AboutVideo :data="orangeVideoModule"></AboutVideo>
        </div>
      </section>
      <TheFooter
        class="section fp-auto-height"
        :class="$style['about_footer']"
      />
    </full-page>
  </Layout>
</template>
<style lang="scss" module>
.section {
  position: relative;
  text-align: center;
}

.sectionBanner {
  padding: 0;
  // background: transparent;
  background: #dbd4cd;
  @include touch {
    background: #7e6340;
  }
  .banner {
    // height: calc(100vh - 195px);
    // height: 978px;
    height: calc(100vh - 130px);
    background: #7e6441;
    margin-top: -1px;
    @include touch {
      height: auto;
    }
    .image {
      background-size: cover;
      background-position: center 1px;
      position: relative;
      flex: 0 0 63.077%;
      max-width: 63.077%;
      @include touch {
        // padding-top: 80%;
        flex: 0 0 100%;
        max-width: 100%;
        // padding-top: 70%;
        padding-top: calc(72% - 130px);
      }
      &:before {
        opacity: 0.19;
        background-image: linear-gradient(
          to left,
          #000000 100%,
          rgba(0, 0, 0, 0) 0%
        );
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
      }
    }
    .content {
      color: #fff;
      font-family: 'Noto Serif TC', serif;
      position: relative;
      flex: 0 0 36.923%;
      max-width: 36.923%;
      @include touch {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .text {
        @include font(17px, 2.06, 1.9px, 500);
        padding: 0 1rem;
        // top: 26.7%;
        left: -33.5%;
        position: absolute;
        width: 760px;
        // max-width: 100vw;
        max-width: 44vw;
        top: 50%;
        transform: translateY(-50%);
        @include touch {
          @include font(14px, null, null, null);
          max-width: 100vw;
          position: static;
          padding: 36px 20px 24px;
          width: 100%;
          margin: 0 auto;
          transform: inherit;
          line-height: 1.5;
        }
        @include touch-sm {
          transform: scale(0.8);
          transform-origin: top;
        }
      }
      .shadow {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        &:before {
          content: 'ORANGE';
          font-family: 'Adobe Garamond Pro', sans-serif;
          position: absolute;
          top: 45%;
          left: -11%;
          color: #b9a187;
          font-size: 250px;
          letter-spacing: 30px;
          opacity: 0.15;
          @include touch {
            top: -12%;
            left: 22%;
            font-size: 110px;
            letter-spacing: 10px;
          }
          @include touch-sm {
            top: -10%;
            font-size: 90px;
          }
        }
      }
    }
  }
}

.sectionBoss {
  // padding: 100px 0 0;
  background: #dbd4cd;
  background-image: linear-gradient(to top, #f5f1ed, #dbd4cd);
  @include touch {
    // padding-top: 35px;
  }
  .sectionContainer {
    width: 1524px;
    max-width: 100vw;
    margin: 0 auto;
    @include touch {
      padding: 0 20px;
    }
    .imgBox {
      flex: 0 0 54.25%;
      max-width: 54.25%;

      @include touch {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        text-align: center !important;
      }
      .img {
        margin-bottom: -7em;
        max-width: 50vw;
        @include touch {
          // max-width: 100vw;
          margin-bottom: 30px;
          // max-width: calc(100vw - 40px);
          max-width: calc(80vw);
        }
        @include touch-sm {
          max-width: calc(70vw);
        }
      }
    }
    .textBox {
      display: flex;
      align-items: flex-end;
      color: #453830;
      font-family: 'Noto Serif TC', serif;
      padding: 0 0 0 80px;
      flex: 0 0 45.75%;
      max-width: 45.75%;
      transform-origin: bottom;
      @include touch {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 15px 3rem;
      }
      .title {
        // @include font(37px, 1.68, 7.4px, 600);
        margin-left: 5.5vw;
        margin-bottom: 60px;
        @include touch {
          // @include font(24px, 1.58, 3.84px, null);
          margin-bottom: 27px;
          margin-left: 15px;
          max-width: calc(100vw - 70px);
        }
      }
      .meta {
        @include font(13px, 36px, 2.6px, 500);
        margin-bottom: 0;
        @include touch {
          @include font(12px, 30px, 2.4px, null);
          padding-left: 40px;
        }
      }
      .name {
        @include font(22px, 36px, 4.4px, 500);
        margin-bottom: 1.5rem;
        @include touch {
          @include font(18px, 30px, 3.6px, null);
          padding-left: 40px;
          margin-bottom: -10px;
        }
      }
      .sign {
        // width: 368px;
        // height: 126px;
        position: relative;
        left: -1.5rem;
        margin-bottom: 65px;
        @include touch {
          margin-bottom: -10px;
          width: 130px;
          left: 110px;
        }
      }
    }
  }
}

.sectionCircle {
  // padding: 11.5rem 0 171px;
  // padding: 5.5rem 0 5.5rem;
  background: #d5cfc7;
  @include touch {
    // padding: 50px 0 80px;
    padding: 0;
  }
  .circleBox {
    width: 705px;
    height: 705px;
    margin: 0 auto;
    position: relative;
    transform: scale(0.8);
    @include touch {
      width: 100%;
      height: auto;
      transform: inherit;
      margin-top: -130px;
    }
    .animationBox {
      width: 100%;
      height: 100%;
      border: 1px solid #58472e;
      border-radius: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transform: rotate(0deg);
      transform-origin: center;
      transition: 0.9s cubic-bezier(0.215, 0.61, 0.355, 1);
      @include touch {
        transform: unset !important;
        border: unset;
        position: static;
        display: flex;
        justify-content: space-around;
      }
      .content {
        display: none;
        @include touch {
          @include font(14px, 28px, 2.1px, 500);
          color: #453830;
          display: block;
          padding: 0 20px;
          margin-bottom: 70px;
          padding-top: 120px;
          position: absolute;
        }
        @include touch-sm {
          padding-top: 100px;
        }
      }
      .circle {
        background: #e2d5c8;
        width: 363px;
        height: 363px;
        border-radius: 100%;
        margin-top: -181.5px;
        margin-left: -181.5px;
        border: 45px solid #d5cfc7;
        cursor: pointer;
        transition: 0.2s;
        transform: scale(1);
        @include touch {
          margin-top: 0;
          margin-left: 0;

          transform: scale(1) !important;
          width: 100px;
          height: 100px;
          border: 0 solid transparent;
          z-index: 1;
          &.active {
            transform: scale(1.2) !important;
            background: #f5f0ea;
          }
        }
        @include touch-sm {
          width: 80px;
          height: 80px;
        }
        &:hover {
          background: #f5f0ea;
        }
        &:active {
          transition: 0s;
          transform: scale(1);
        }
        &.circleA {
          position: absolute;
          top: 0;
          left: 50%;
          @include touch {
            position: static;
            margin: 0 auto 34px;
          }
        }
        &.circleB {
          position: absolute;
          top: 74%;
          left: 6%;
          .img {
            margin-left: -25px;
          }
          @include touch {
            position: static;
            margin: 0 auto 34px;
            .img {
              margin-left: inherit;
            }
          }
        }
        &.circleC {
          position: absolute;
          top: 74%;
          left: 94%;
          @include touch {
            position: static;
            margin: 0 auto 34px;
          }
        }
        .circleInner {
          transition: 0.9s cubic-bezier(0.215, 0.61, 0.355, 1);
          transform-origin: center;
          transform: rotate(0deg);
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
          text-align: center;
          @include touch {
            transform: unset !important;
          }
          .img {
            margin-top: 2rem;
            height: 85px;
            margin-bottom: 1.5rem;
            @include touch {
              height: 31px;
              margin-bottom: 0.2rem;
              margin-top: 1rem;
            }
            @include touch-sm {
              height: 21px;
            }
          }
          .title {
            @include font(20px, 26px, 0.8px, 400);
            font-family: 'Palatino', sans-serif;
            text-transform: capitalize;
            color: #453830;
            @include touch {
              font-size: 10px;
            }
            @include touch-sm {
              transform: scale(0.8);
            }
          }
        }
      }
    }

    .textBox {
      color: #453830;
      pointer-events: none;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      .text {
        @include font(16.5px, 26px, 3px, 600);
        font-family: 'Noto Serif TC', serif;
        width: 49%;
        position: relative;
        z-index: 1;
        text-align: center;
      }
      @include touch {
        display: none;
      }
    }
  }
  :global {
    .fp-tableCell {
      display: flex;
      align-items: center;
    }
  }
}

.sectionBannerA {
  // padding: 113px 0 91px;
  padding: 0;
  background: #7e6441;
  font-family: 'Noto Serif TC', serif;
  color: #fff;
  text-align: center;
  :global {
    .fp-tableCell {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  @include touch {
    // padding: 80px 0;
    padding: 0;
  }
  .title {
    @include font(32px, 56px, 12px, 600);
    margin-bottom: 4rem;
    @include touch {
      @include font(28px, 1.71, 8.4px, 500);
      margin-bottom: 65px;
    }
    @include touch-sm {
      margin-bottom: 40px;
      margin-top: -70px;
      font-size: 25px;
    }
  }
  .content {
    @include font(18px, 30px, 7px, 600);
    margin-bottom: 4rem;
    @include touch {
      @include font(14px, 26px, 1.12px, 500);
    }
    @include touch-sm {
      margin-bottom: 2rem;
    }
  }
  .line {
    height: 36px;
    width: 1px;
    background: #fff;
    margin: 0 auto;
  }
}

.sectionBannerB {
  // padding: 75px 0;
  background: #ccbeb0;
  text-align: center;
  font-family: 'Noto Serif TC', serif;
  @include touch {
    // padding: 50px 0;
  }
  .title {
    @include font(24px, 62px, 4.8px, 600);
    color: #000;
    @include touch {
      @include font(26px, 2, 3px, 600);
      margin-bottom: 0;
    }
  }
  :global {
    .fp-tableCell {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.sectionTimeline {
  padding: 0;
  background: #2b251b;
  background-image: linear-gradient(to top, #2b251b, #987850);
  .img {
    background-size: cover;
    background-position: 75%;
    // padding-top: 26.042%;
    padding-top: 15%;
    @include touch {
      padding-top: 33.07%;
    }
  }
  .timeLine {
    // padding: 7.5rem 0;
    padding: 7.5rem 0 0;
    @include touch {
      padding: 1rem 0 40px;
    }
  }
}

.sectionVideo {
  padding: 0;
  background-image: url('@assets/images/about/about_video_banner.jpg');
  position: relative;
  z-index: 1;
  background-size: cover;
  background-position: center;
  &:before {
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0%,
      #000000 100%
    );
    content: '';
    height: 100%;
    width: 749px;
    position: absolute;
    left: 50%;
    margin-left: -375px;
    @include touch {
      @include bg-mask(rgba(#0a0a0a, 0.5));
      margin-left: 0;
    }
  }
  @include touch {
    background-position: -480px;
    // background-image: url('@assets/images/about/about_video_banner_m.jpg');
  }
}

.about_footer {
  padding-top: 0;
  padding-bottom: 18px;
  @include touch {
    padding: 18px 44px;
  }
  :global {
    .d-flex {
      height: 300px;
      @include touch {
        height: auto;
      }
    }
  }
}
</style>
