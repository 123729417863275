var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('full-page',{ref:"fullpage",attrs:{"options":_vm.options}},[_c('section',{staticClass:"section",class:_vm.$style.sectionBanner},[_c('div',{staticClass:"row",class:_vm.$style.banner},[_c('div',{class:_vm.$style.image,style:({ backgroundImage: ("url(" + (_vm.bannerModule.image) + ")") })}),_c('div',{class:_vm.$style.content},[_c('div',{directives:[{name:"fade-up",rawName:"v-fade-up"}],class:_vm.$style.text,domProps:{"innerHTML":_vm._s(_vm.mainContentModule.content)}}),_c('div',{directives:[{name:"fade-up",rawName:"v-fade-up"}],class:_vm.$style.shadow})])])]),_c('section',{staticClass:"section",class:_vm.$style.sectionBoss},[_c('div',{class:_vm.$style.sectionContainer},[_c('div',{staticClass:"row"},[_c('div',{directives:[{name:"fade-up",rawName:"v-fade-up"}],staticClass:"text-right",class:_vm.$style.imgBox},[_c('img',{class:_vm.$style.img,attrs:{"alt":_vm.$t('global.YUAN'),"src":require("@assets/images/about/boss.png")}})]),_c('div',{class:_vm.$style.textBox,style:(_vm.bossTranformStyle)},[_c('div',[_c('img',{directives:[{name:"fade-up",rawName:"v-fade-up"}],class:_vm.$style.title,attrs:{"alt":_vm.$t('global.W_H_F_S'),"src":require("@assets/images/about/boss_text.png")}}),_c('div',{directives:[{name:"fade-up",rawName:"v-fade-up"}]},[_c('h5',{class:_vm.$style.meta},[_vm._v(_vm._s(_vm.$t("global.FOUNDER")))]),_c('h4',{class:_vm.$style.name},[_vm._v(_vm._s(_vm.$t('global.YUAN')))]),_c('img',{class:_vm.$style.sign,attrs:{"alt":_vm.$t('global.SIGNATURE'),"src":require("@assets/images/about/boss_sign.png")}})])])])])])]),_c('section',{staticClass:"section",class:_vm.$style.sectionCircle},[_c('div',{directives:[{name:"fade-up",rawName:"v-fade-up"}],class:_vm.$style.circleBox},[_c('div',{class:_vm.$style.animationBox,style:(_vm.circleStyle)},[_c('div',{class:[
              _vm.$style.circle,
              _vm.$style.circleA,
              _vm.circleValue.index === 0 ? _vm.$style.active : '' ],on:{"click":function($event){return _vm.setCircleValue(0)}}},[_c('div',{class:_vm.$style.circleInner,style:(_vm.circleInnerStyle)},[_c('div',[_c('img',{class:_vm.$style.img,attrs:{"alt":_vm.circleModule.items[0].title,"src":_vm.circleModule.items[0].image}}),_c('h6',{class:_vm.$style.title},[_vm._v(_vm._s(_vm.circleModule.items[0].title))])])])]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.isMobile && _vm.circleValue.index === 0)?_c('p',{class:_vm.$style.content},[_vm._v(_vm._s(_vm.circleModule.items[0].content))]):_vm._e()]),_c('div',{class:[
              _vm.$style.circle,
              _vm.$style.circleB,
              _vm.circleValue.index === 1 ? _vm.$style.active : '' ],on:{"click":function($event){return _vm.setCircleValue(1)}}},[_c('div',{class:_vm.$style.circleInner,style:(_vm.circleInnerStyle)},[_c('div',[_c('img',{class:_vm.$style.img,attrs:{"alt":_vm.circleModule.items[1].title,"src":_vm.circleModule.items[1].image}}),_c('h6',{class:_vm.$style.title},[_vm._v(_vm._s(_vm.circleModule.items[1].title))])])])]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.isMobile && _vm.circleValue.index === 1)?_c('p',{class:_vm.$style.content},[_vm._v(_vm._s(_vm.circleModule.items[1].content))]):_vm._e()]),_c('div',{class:[
              _vm.$style.circle,
              _vm.$style.circleC,
              _vm.circleValue.index === 2 ? _vm.$style.active : '' ],on:{"click":function($event){return _vm.setCircleValue(2)}}},[_c('div',{class:_vm.$style.circleInner,style:(_vm.circleInnerStyle)},[_c('div',[_c('img',{class:_vm.$style.img,attrs:{"alt":_vm.circleModule.items[2].title,"src":_vm.circleModule.items[2].image}}),_c('h6',{class:_vm.$style.title},[_vm._v(_vm._s(_vm.circleModule.items[2].title))])])])]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.isMobile && _vm.circleValue.index === 2)?_c('p',{class:_vm.$style.content},[_vm._v(_vm._s(_vm.circleModule.items[2].content))]):_vm._e()])],1),_c('div',{class:_vm.$style.textBox},[_c('div',{class:_vm.$style.text},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_vm._l((_vm.circleModule.items),function(circleItem,index){return [(index === _vm.circleValue.index)?_c('div',{key:circleItem.title},[_vm._v("\n                  "+_vm._s(circleItem.content)+"\n                ")]):_vm._e()]})],2)],1)])])]),_c('section',{staticClass:"section",class:_vm.$style.sectionBannerA},[_c('div',{staticClass:"container"},[_c('h2',{directives:[{name:"fade-up",rawName:"v-fade-up"}],class:_vm.$style.title,domProps:{"innerHTML":_vm._s(_vm.expectModule.title)}}),_c('p',{directives:[{name:"fade-up",rawName:"v-fade-up"}],class:_vm.$style.content,domProps:{"innerHTML":_vm._s(_vm.expectModule.content)}}),_c('div',{directives:[{name:"fade-up",rawName:"v-fade-up"}],class:_vm.$style.line})])]),_c('section',{staticClass:"section",class:_vm.$style.sectionBannerB},[_c('h2',{directives:[{name:"fade-up",rawName:"v-fade-up"}],class:_vm.$style.title,domProps:{"innerHTML":_vm._s(_vm.persistenceModule.title)}})]),_c('section',{staticClass:"section",class:_vm.$style.sectionTimeline},[_c('div',{class:_vm.$style.img,style:({
          backgroundImage: ("url(" + (require('@assets/images/about/about_timeline_banner.png')) + ")"),
        })}),_c('div',{staticClass:"container"},[_c('div',{directives:[{name:"fade-up",rawName:"v-fade-up"}],class:_vm.$style.timeLine},[(_vm.timeLineModule)?_c('AboutTimeLine',{attrs:{"module-data":_vm.timeLineModule}}):_vm._e()],1)])]),_c('section',{staticClass:"section",class:_vm.$style.sectionVideo},[_c('div',{class:_vm.$style.video},[_c('AboutVideo',{attrs:{"data":_vm.orangeVideoModule}})],1)]),_c('TheFooter',{staticClass:"section fp-auto-height",class:_vm.$style['about_footer']})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }