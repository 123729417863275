<script>


// https://www.npmjs.com/package/vue-youtube-embed
/* import Vue from "vue";
import VueYouTubeEmbed from "vue-youtube-embed";
Vue.use(VueYouTubeEmbed); */

// https://github.com/surmon-china/vue-video-player
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";

export default {
  components: {
    videoPlayer
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      playerOptions: {
        // videojs options
        sources: [
          {
            type: "video/mp4",
            src: require('@assets/videos/about.mp4'),
          }
        ],
        poster: '',
      },
      showLightBox: false,
    }
  },
  methods: {
    onLightBoxClose() {
      this.showLightBox = false;
      this.$refs.videoPlayer.player.pause();
    },
    onLightBoxOpen() {
      this.showLightBox = true;
      setTimeout(()=> {
        this.$refs.videoPlayer.player.play();
      }, 600)
    },
  }
};
</script>
<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-xl-7"></div>
      <div class="col-lg-6 col-xl-5">
        <div v-fade-up :class="$style.textBox">
          <div class="d-flex">
            <h2 :class="$style.title" v-html="data.title"></h2>
            <button
              class="ml-5 d-none d-lg-inline-flex"
              :class="$style.play"
              @click="onLightBoxOpen"
            ></button>
          </div>
          <p :class="$style.content" v-html="data.content"></p>
          <div class="text-center d-lg-none d-flex justify-content-center">
            <button
              :class="$style.play"
              @click="onLightBoxOpen"
            ></button>
          </div>

        </div>

      </div>
    </div>
   <!--<transition name="fade">
      <div v-if="showLightBox" :class="[$style.lightBox]" >
        <videoPlayer
          ref="videoPlayer"
          :options="playerOptions"
          :class="$style.videoPlayer"
        />
        <button
          :class="$style.lightBoxClose"
          @click="onLightBoxClose"
        >
          <font-awesome-icon :icon="['fal', 'times']"></font-awesome-icon>
        </button>
      </div>
    </transition>-->
    <el-dialog
      :visible.sync="showLightBox"
      width="100%"
      custom-class="styleAboutVideo"
      append-to-body
      :before-close="onLightBoxClose">
      <div>
        <videoPlayer
          ref="videoPlayer"
          :options="playerOptions"
          :class="$style.videoPlayer"
        />
        <button
          class="lightBoxClose"
          @click="onLightBoxClose"
        >
          <font-awesome-icon :icon="['fal', 'times']"></font-awesome-icon>
        </button>
      </div>
    </el-dialog>
  </div>
</template>
<style lang="scss" module>
  .videoPlayer {
    & > div {
      height: 100vh;
      width: 100vw;
    }
  }
  .lightBox {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 30;
    background: rgba(0, 0, 0, 0.96);
    transform: scale(1);
    .lightBoxClose {
      appearance: none;
      background: transparent;
      border: 0px solid transparent;
      position: absolute;
      top: 1rem;
      right: 1rem;
      color: #fff;
      font-size: 30px;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
      &:focus {
        outline: none;
      }
    }
  }
  .textBox {
    color: #fff;
    font-family: 'Noto Serif TC', serif;
    width: 600px;
    max-width: 40vw;
    padding: 11rem 0;
    @include touch {
      max-width: 100vw;
      padding: 146px 20px;
      width: 540px;
      margin: auto;
    }
    .title {
      @include font(30px, 1.53, 6px, 500);
      margin-bottom: 3rem;
      @include touch {
        @include font(32px, 42px, 6.4px, 500);
        margin: 0 auto 32px;
        br {
          display: none;
        }
      }
      @include touch-sm {
        font-size: 27px;
        margin-top: -40px;
      }
    }
    .content {
      @include font(16px, 1.63, 3.2px, 600);
      @include touch {
        @include font(14px, 20px, 1.82px, 600);
        margin-bottom: 32px;
      }
    }
  }
  .play {
    margin-top: 0.5rem;
    appearance: none;
    border: 1px solid #fff;
    background: transparent;
    color: #fff;
    border-radius: 100%;
    height: 70px;
    width: 70px;
    font-size: 30px;
    padding-left: 13px;
    text-align: center;
    padding-right: 4px;
    position: relative;
    &:before {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 13px 0 13px 23px;
      border-color: transparent transparent transparent #ffffff;
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -8px;
      margin-top: -12.5px;
    }
    &:hover {
       color: #ad9d8b;
       border-color: #ad9d8b;
      &:before {
        border-color: transparent transparent transparent #ad9d8b;
      }
    }
    &:active {
      font-size: 28px;
    }
    &:focus {
      outline: none;
    }
  }
</style>
<style>
  .video-js .vjs-big-play-button {
    top: 50%;
    left: 50%;
    display: none;
  }
</style>
