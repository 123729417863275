
<script>
import { formatTimeLineDate } from '@utils/format-date'

// https://github.com/surmon-china/vue-awesome-swiper
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

export default {
  components: { swiper, swiperSlide },
  props: {
    moduleData: {
      type: Object,
      required: true
    }
  },
  data() {
    const $self = this;
    return {
      pageCount: 0,
      swiperOption: {
        slidesPerView: 7,
        grabCursor: true,
        centeredSlides: true,
        breakpoints: {
          768: {
            slidesPerView: 3,
          },
        },
        on: {
          slideChange () {
            $self.pageCount = ( this.activeIndex % $self.yearList.length ) || 0
          }
        },
      },
    }
  },
  computed: {
    yearList() {
      return Object.keys(this.moduleData)
    },
    yearContent() {
      const index = this.yearList[this.pageCount];
      return this.moduleData[index]
    }
  },
  mounted() {
    this.setSlide(this.yearList.length)
  },
  methods: {
    setSlide(index) {
      this.$refs.timelineSwiper.swiper.slideTo(index, 600)
    },
    onNext() {
      if(this.pageCount < this.yearList.length) {
        this.setSlide(this.pageCount + 1);
      }
    },
    onPrev() {
      if(this.pageCount > 0) {
        this.setSlide(this.pageCount - 1);
      }
    },
    getExtrafields(item, field) {
      console.log(item, field)
      if(field in item.extrafields){
        return item.extrafields[field].value;
      }else{
        return false
      }
    },
    formatNtoBr(text) {
      return text.replace(/\r\n/g,"<br>").replace(/\n/g,"<br>");
    },
    formatTimeLineDate,
  },
};
</script>
<template>
  <div class="position-relative">
    <swiper ref="timelineSwiper" :class="$style.carousel" :options="swiperOption">
      <!-- slides -->
      <swiper-slide v-for="(year, index) in yearList" :key="`${index}carouselSlide`"
                    :class="[$style.carouselSlide,
      index === pageCount ? $style.hasActive : '']">
        <div :class="$style.carouselContent">
          <div :class="$style.titleBox">
            <h5 :class="$style.title" @click="setSlide(index)">{{year}}</h5>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <transition name="fade" mode="out-in">
      <template v-for="(year, index) in yearList">
        <div v-if="index === pageCount" :key="`${index}year`">
          <div v-for="(item, $key2, index2) in moduleData[year]" :key="`${index2}item`"
               :class="$style.contentBox">
            <div v-for="(item3, $key3, index3) in moduleData[year][$key2]" :key="`${index3}item`" class="row mb-5 mb-lg-0">
              <div class="col-md-6">
                <h6 :class="$style.meta">{{ formatTimeLineDate(new Date(`${year}/${$key2}/05 00:00:00`))}}</h6>
                <h4 :class="$style.title">
                  {{item3.title}}
                </h4>
              </div>
              <div :class="$style.desc" class="col-md-6" v-html="formatNtoBr(item3.extrafields[0].value)">
              </div>
            </div>
          </div>
        </div>
      </template>
    </transition>
    <div :class="$style.navigationBox">
      <button
        :class="[$style.navigationPrev, $style.navigationBtn]"
        @click="onPrev"
      >
        <font-awesome-icon :icon="['fas', 'caret-left']"></font-awesome-icon>
      </button>
      <button
        :class="[$style.navigationNext, $style.navigationBtn]"
        @click="onNext"
      >
        <font-awesome-icon :icon="['fas', 'caret-right']"></font-awesome-icon>
      </button>
    </div>
  </div>
</template>
<style lang="scss" module>
  .carousel {
    @include touch {
      width: 80%;
      margin-bottom: -50px;
    }
    &:before {
      content: "";
      position: absolute;
      background: #f5f1ed;
      top: 70px;
      width: 100%;
      height: 1px;
    }
    .carouselSlide {
      height: 80px;
      position: relative;
      margin-bottom: 4rem;
      &:before {
        content: "";
        height: 6px;
        width: 2px;
        background: #f5f1ed;
        position: absolute;
        left: 50%;
        top: 68px;
        border-radius: 100%;
      }
      &.hasActive {
        &:before {
          transition: all 0.3s;
          height: 16px;
          width: 16px;
          top: 63px;
          margin-left: -8px;
        }
        .carouselContent {
          font-size: 36px;
          .titleBox {
            .title {
              font-weight: 400;
              font-size: 36px;
              @include touch {
                font-size: 24px;
                letter-spacing: 1.12px;
              }
            }
          }
        }
      }
      .carouselContent {
        color: #f5f1ed;
        text-align: center;
        letter-spacing: 1px;
        .titleBox {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          height: 50px;
          .title {
            line-height: 0.8;
            margin-bottom: 0;
            font-size: 14px;
            transition: font-size 0.3s;
            font-family: Palatino, 'Noto Serif TC', serif;
            margin-bottom: 0.5rem;
            @include touch {
              margin-bottom: -0.5rem;
            }
          }
        }

      }
    }
  }
  .contentBox {
    color: #f5f1ed;
    padding: 0 5rem;
    @include touch {
      padding: 0;
    }
    .meta {
      @include font(14px, 93px, 1px, 400);
      font-family: Palatino, 'Noto Serif TC', serif;
      margin-bottom: 1rem;
    }
    .title {
      @include font(20px, 34px, 1px, 400);
      font-family: Palatino, 'Noto Serif TC', serif;
      margin-top: -40px;
      @include touch {
        @include font(16px, null, 0.64px, null);
        // font-size: 16px;
        // letter-spacing: 0.64px;
      }
    }

     .desc {
       @include font(14px, 21px, 3px, 300);
       font-family: PingFangTC-Light, Noto Sans TC, sans-serif;
       min-height: 14em;
       margin-top: 4.5rem;
       @include touch {
         min-height: 17em;
         letter-spacing: 2.24px;
         margin-top: 1rem;
       }
    }
  }
  .navigationBox {
    position: absolute;
    width: 100%;
    top: 70px;
    .navigationBtn {
      width: 100px;
      height: 100px;
      position: absolute;
      top: 50%;
      color: #f5f1ed;
      appearance: none;
      background: transparent;
      border: 0px solid transparent;
      margin-top: -50px;
      text-align: center;
      transform: scaleY(0.6);
      font-size: 1.5rem;
      &:focus {
        outline: none;
      }
      &:active {
        opacity: 0.5;
      }
    }
    .navigationPrev {
      left: -150px;
      @include touch {
        left: -3rem;
      }
    }
    .navigationNext {
      right: -150px;
      @include touch {
        right: -3rem;
      }
    }
  }

</style>
